<template>
    <Top />
    <!-- Spinner Start -->
    <div id="spinner" v-if="halamanloading"
        class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        &nbsp;<img src="../../../../src/assets/img/BerandaNgumahLogo.png" height="40">
        &nbsp;<span class="text-danger">..Loading...</span>
    </div>
    <!-- Spinner End -->
    <!-- Header Start -->
    <div id="myModal" class="modal">
        <span class="close">&times;</span>
        <img class="modal-content" id="img01">
        <div id="caption"></div>
    </div>
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center py-5" style="max-width: 900px;">
            <h3 class="text-white display-3 mb-4 wow fadeInDown pmisputih" data-wow-delay="0.1s">
                <span v-if="(bahasa === null) || (bahasa == 'ina')">INFOGRAFIS</span>
                <span v-else>INFOGRAFIS</span>
            </h3>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s">
                <li class="breadcrumb-item">
                    <a href="/">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Beranda</span>
                        <span v-else>Home</span>
                    </a>
                </li>
                <li class="breadcrumb-item active text-primary">
                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Infografis Tata Ruang</span>
                    <span v-else>Infografis Tata Ruang</span>
                </li>
            </ol>
        </div>
    </div>
    <!-- Header End -->
    <!-- Contact Start -->
    <div class="container-fluid blog py-2">&nbsp;</div>
    <div class="container-fluid bg-breadcrumbberanda wow fadeInDown">
        <Carousel :wrap-around="true" :breakpoints="breakpoints" :autoplay="5000">
            <Slide v-for="slide in slides" :key="slide.id">
                <div class="carousel__item"
                    :style="'background:linear-gradient(0deg, rgba(60, 60, 60, 0.5), rgba(60, 60, 60, 0.5)), url(' + slide.url + ');background-position: center;background-repeat: no-repeat;background-size: cover;background-color: rgba(223, 12, 12, 0);'">
                    <h1 class="col-sm-12 carousel__h1" style="font-style: normal;cursor: pointer;" @click="ambilgambar(slide.url,slide.title)">{{ slide.title }}</h1>
                    <p class="col-sm-12 text-center" style="cursor: pointer;" @click="ambilgambar(slide.url,slide.title)"><span v-html="slide.content"></span></p>
                </div>
            </Slide>
            <template #addons>
                <Navigation />
                <Pagination />
            </template>
        </Carousel>
    </div>
    <!-- Contact End -->
    <a href="#" class="btn btn-dangerbulet btn-lg-square back-to-top text-white"><i class="fa fa-arrow-up"></i></a>
    <Footer />
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import swal from 'sweetalert2';
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import Top from "./Template/Top.vue";
import Footer from "./Template/Footer.vue";

export default {
    components: {
        Top, Footer, swal, Carousel, Slide, Pagination, Navigation,
    },
    data() {
        return {
            halamanloading: true,
            tahun: new Date().getFullYear(),
            bahasa: localStorage.getItem("bahasasistem"),
            sourcenya: '',
            slides: [],
            breakpoints: {
                // 700px and up
                700: {
                    itemsToShow: 1,
                    snapAlign: 'center',
                },
                // 1024 and up
                1024: {
                    itemsToShow: 1,
                    snapAlign: 'start',
                },
            },
        };
    },
    methods: {
        ambilgambar(id,konten){
            //alert(id);
            var modal = document.getElementById("myModal");
            // Get the image and insert it inside the modal - use its "alt" text as a caption
            var modalImg = document.getElementById("img01");
            var captionText = document.getElementById("caption");
            modal.style.display = "block";
            modalImg.src = id;
            captionText.innerHTML = konten;

            // Get the <span> element that closes the modal
            var span = document.getElementsByClassName("close")[0];

            // When the user clicks on <span> (x), close the modal
            span.onclick = function () {
                modal.style.display = "none";
            }
        },
        async ambildata() {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            var random = Math.random();
            await mainAPI.get("parastapainnovation-GetInfografis?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datamaster = [];
                        this.slides = [];
                        this.datamaster = Response.data.content.data;
                        if ((this.bahasa == null) || (this.bahasa == 'ina')) {
                            this.datamaster.forEach((item) => {
                                this.slides.push({
                                    id: item.id,
                                    title: item.nama,
                                    content: atob(item.konten),
                                    url: process.env.VUE_APP_URL_API + 'parastapainnovation_infografis-Gambar?f=' + item.filenya_infografis,
                                });
                            });
                        }
                        else {
                            this.datamaster.forEach((item) => {
                                this.slides.push({
                                    id: item.id,
                                    title: item.nama_eng,
                                    content: atob(item.konten_eng),
                                    url: process.env.VUE_APP_URL_API + 'parastapainnovation_infografis-Gambar?f=' + item.filenya_infografis,
                                });
                            });
                        }
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = [];
                this.datamaster = [];
                this.slides = [];
                console.log(error);
                this.halamanloading = false;
            });
        },        
    },
    mounted() {
        this.ambildata();
    },
}
</script>

<style>
.carousel__prev,
.carousel__next {
    box-sizing: content-box;
    border: 5px solid #0055aa;
    border-radius: 40px;
    background-color: #0055aa;
    color: aliceblue;
    height: 50px;
    width: 50px;
}
</style>