<template>
    <div class="container-fluid bg-dark px-5 d-none d-lg-block">
        <div class="row gx-0 align-items-center" style="height: 45px;">
            <div class="col-lg-8 text-center text-lg-start mb-lg-0">
                <div class="d-flex flex-wrap">
                    <a href="#" class="text-light me-4">Dinas PUSDATARU Provinsi Jawa Tengah</a>
                    <a href="#" class="text-light me-4"><i class="fas fa-phone-alt text-danger me-2"></i>081-390-075705</a>
                    <a href="https://www.instagram.com/dpusdatarujtg/?hl=id" class="text-light me-0" target="_blank"><i
                            class="fab fa-instagram text-danger me-2"></i>dpusdatarujtg</a>
                </div>
            </div>
            <div class="col-lg-4 text-center text-lg-end">
                <div class="d-flex align-items-center justify-content-end">
                    <a href="#" class="btn nav-fill me-6 text-white">#lintangjateng</a>
                </div>
            </div>
        </div>
    </div>
    <!-- Topbar End -->
    <div class="container-fluid position-relative p-0">
        <nav class="navbar navbar-expand-lg navbar-light bg-white px-4 px-lg-5 py-3 py-lg-0">
            <a href="/" class="navbar-brand p-0">
                <h5 class="text-danger m-0 pmisdanger"><img src="../../../../../src/assets/img/berandaNgumahNew.png"
                        height="60"></h5>
                <!-- <img src="img/logo.png" alt="Logo"> -->
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                <span class="fa fa-bars"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarCollapse">
                <div class="navbar-nav ms-auto py-0">
                    <a href="/"
                        :class="[((route.name == 'Beranda') ? 'nav-item nav-link active' : 'nav-item nav-link')]">
                        BERANDA
                    </a>
                    <a href="/DataProfil"
                        :class="[((route.name == 'DataProfil') ? 'nav-item nav-link active' : 'nav-item nav-link')]">
                        PROFIL
                    </a>
                    <div class="nav-item dropdown">
                        <a href="#" data-bs-toggle="dropdown"
                            :class="[(((route.name == 'DaftarSekilas') || (route.name == 'DaftarBerita')
                                || (route.name == 'DaftarPustaka') || (route.name == 'DaftarGaleri') 
                                || (route.name == 'DaftarInfografis') || (route.name == 'DaftarProduk')
                                || (route.name == 'DetailProduk')) ? 'nav-link active dropdown-toggle' : 'nav-link dropdown-toggle')]">
                            INFORMASI
                        </a>
                        <div class="dropdown-menu m-0">
                            <a :href="'/DaftarSekilas'"
                                :class="[((route.name == 'DaftarSekilas') ? 'dropdown-item active' : 'dropdown-item')]">
                                SEKILAS TATA RUANG
                            </a>
                            <a :href="'/DaftarBerita'"
                                :class="[((route.name == 'DaftarBerita') ? 'dropdown-item active' : 'dropdown-item')]">
                                BERITA & GALERI TATA RUANG
                            </a>
                            <a :href="'/DaftarPustaka'"
                                :class="[((route.name == 'DaftarPustaka') ? 'dropdown-item active' : 'dropdown-item')]">
                                PUSTAKA TATA RUANG
                            </a>
                            <a :href="'/DaftarInfografis'"
                                :class="[((route.name == 'DaftarInfografis') ? 'dropdown-item active' : 'dropdown-item')]">
                                INFOGRAFIS TATA RUANG
                            </a>
                            <a :href="'/DaftarProduk'"
                                :class="[((route.name == 'DaftarProduk' || route.name == 'DetailProduk') ? 'dropdown-item active' : 'dropdown-item')]">
                                PRODUK HUKUM TATA RUANG
                            </a>
                        </div>
                    </div>
                    <div class="nav-item dropdown">
                        <a href="#" data-bs-toggle="dropdown"
                            :class="[(((route.name == 'DaftarRTR') || (route.name == 'DaftarSPPR')
                                || (route.name == 'DaftarRTH') || (route.name == 'DaftarPengedalian')) ? 'nav-link active dropdown-toggle' : 'nav-link dropdown-toggle')]">
                            PELAKSANAAN PENATAAN RUANG
                        </a>
                        <div class="dropdown-menu m-0">
                            <a :href="'/DaftarRTR'"
                                :class="[((route.name == 'DaftarRTR') ? 'dropdown-item active' : 'dropdown-item')]">
                                PENYUSUNAN DAN PENETAPAN RTR
                            </a>
                            <a :href="'/DaftarRTH'"
                                :class="[((route.name == 'DaftarRTH') ? 'dropdown-item active' : 'dropdown-item')]">
                                PEMANTAUAN PEMENUHAN RTH KABUPATEN / KOTA
                            </a>
                            <a :href="'/DaftarPengedalian'"
                                :class="[((route.name == 'DaftarPengedalian') ? 'dropdown-item active' : 'dropdown-item')]">
                                PENGENDALIAN PEMANFAATAN RUANG
                            </a>
                        </div>
                    </div>
                    <div class="nav-item dropdown">
                        <a href="#" data-bs-toggle="dropdown"
                            :class="[(((route.name == 'GIS')) ? 'nav-link active dropdown-toggle' : 'nav-link dropdown-toggle')]">
                            WEB GIS
                        </a>
                        <div class="dropdown-menu m-0">
                            <a href="https://gistaru.atrbpn.go.id/rdtrinteraktif/" target="_blank"
                                :class="[((route.name == 'ATRBPN') ? 'dropdown-item active' : 'dropdown-item')]">
                                RDTR INTERAKTIF ATR BPN
                            </a>
                            <a href="https://gistaru.atrbpn.go.id/rtronline/" target="_blank"
                                :class="[((route.name == 'ATRBPN2') ? 'dropdown-item active' : 'dropdown-item')]">
                                RTR ONLINE ATR BPN
                            </a>
                            <a :href="'/GIS/'+bytes" target="_blank"
                                :class="[((route.name == 'GIS') ? 'dropdown-item active' : 'dropdown-item')]">
                                WEB GIS TATA RUANG JATENG
                            </a>
                        </div>
                    </div>
                    <a href="/KontakKami"
                        :class="[((route.name == 'KontakKami') ? 'nav-item nav-link active' : 'nav-item nav-link')]">
                        KONTAK KAMI
                    </a>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import { useRoute } from 'vue-router';
import { watch } from 'vue';
import swal from 'sweetalert2';
import CryptoJS from 'crypto-js';

export default {
    setup() {
        const route = useRoute()
        watch(() => route.name, () => {
        });

        return { route };
    },
    components: {
        swal
    },
    data() {
        return {
            tabaktif: encodeURIComponent(CryptoJS.AES.encrypt('', 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString()),
            tahun: new Date().getFullYear(),
            bahasa: localStorage.getItem("bahasasistem"),
            bytes: encodeURIComponent(CryptoJS.AES.encrypt('Kosong', 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString()),
        };
    },
    methods: {
        gantibahasa(bahasanya) {
            localStorage.setItem("bahasasistem", bahasanya);
            location.reload();
        },
    },
    mounted() {

    },
}
</script>

<style></style>