<template>
    <div class="wrapper">
        <NavBar />
        <Menu />
        <!-- Spinner Start -->
        <div id="spinner" v-if="halamanloading"
            class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
            style="opacity: 0.5;">
            <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <!-- Spinner End -->
        <center>
            <div class="modal fade" id="costumModaldisclaimer" data-easein="flipBounceYIn" tabindex="-1" role="dialog"
                aria-labelledby="costumModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false"
                style="margin: auto;">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Lampiran</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body text-justify">
                            <p>
                            <div class="col-sm-12">
                                <video style="width: 100%;height:55vh" controls id="framevideo">
                                    <source src="" type="video/mp4">
                                    Your browser does not support the video tag.
                                </video>
                                <iframe src="" id="iframefile" style="width: 100%;height: 55vh;"></iframe>
                            </div>
                            </p>
                        </div>
                        <div class="modal-footer justify-content-between">
                            <button type="button" class="btn btn-danger btn-sm lebar" data-dismiss="modal"><i
                                    class="fas fa-window-close"></i> Tutup</button>
                        </div>
                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
            <!-- /.modal -->
        </center>
        <div class="content-wrapper py-3">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6 wow fadeInDown">
                            <h5>Pengaturan</h5>
                        </div>
                        <div class="col-sm-6 wow fadeInDown">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="/Dashboard">Home </a></li>
                                <li class="breadcrumb-item"><a href="/Berita">Berita</a></li>
                                <li class="breadcrumb-item active">Pengaturan Berita</li>
                            </ol>
                        </div>
                    </div><!-- /.row -->
                </div><!-- /.container-fluid -->
            </div>
            <!-- /.content-header -->
            <div class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-12 wow fadeInDown">
                            <div class="card cardputih2">
                                <div class="card-header border-transparent">
                                    <h3 class="card-title">Form Input Berita</h3>
                                    <div class="card-tools">
                                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                            <i class="fas fa-minus"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="card-body p-3">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <label>Tanggal Berita <small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="date" class="form-control form-control-sm" id="tanggal"
                                                    name="tanggal" placeholder="Tanggal Berita" v-model="tanggal">
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <label>Nama Berita <small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm" id="nama"
                                                    name="nama" placeholder="Nama Berita" v-model="nama">
                                            </div>
                                        </div>

                                        <div class="card-body p-3" v-for="(koodinatsetuju, counter) in koodinatsetujus"
                                            v-bind:key="counter">
                                            <div class="card-header border-transparent">
                                                <h3 class="card-title">File ke-<i>{{ counter + 1 }}</i></h3>
                                                <div class="card-tools">
                                                    <button type="button" class="btn btn-sm btn-primary"
                                                        @click="addKoordinat()">
                                                        <i class="fas fa-plus"></i>
                                                    </button>
                                                    <button type="button" class="btn btn-sm btn-secondary"
                                                        @click="deleteKoordinat()" v-if="(counter > 0)">
                                                        <i class="fas fa-minus"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <!--IKON PETA 1-->
                                                <div class="col-sm-12">
                                                    <label>File<small class="text-info"> (*jpg)</small>
                                                    </label>
                                                    <label v-if="koodinatsetuju.filenya_berita != ''"
                                                        style="float: right;">
                                                        <a href="javascript:void(0)"
                                                            @click="lihatlampiran(koodinatsetuju.filenya_berita)"><i
                                                                class="fas fa-file-image"></i> Lihat
                                                            File</a></label>
                                                    <div class="input-group mb-3">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"><i
                                                                    class="far fa-file-image"></i></span>
                                                        </div>
                                                        <input type="file" class="form-control form-control-sm"
                                                            :id="'fileToUploadfilenya_berita' + (counter + 1)"
                                                            name="fileToUploadfilenya_berita" placeholder="fileToUpload"
                                                            style="font-size: smaller;" accept="image/*">
                                                        <input type="hidden" class="form-control form-control-sm"
                                                            :id="'filenya_berita' + (counter + 1)"
                                                            :name="'filenya_berita' + (counter + 1)"
                                                            placeholder="File Beranda"
                                                            :value="koodinatsetuju.filenya_berita">

                                                        <span :id="'templabelfilenya_berita' + (counter + 1)"
                                                            style="display: none;">{{
                                                                koodinatsetuju.filenya_berita }}</span>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12">
                                                    <label>Opsi</label>
                                                    <div class="row">
                                                        <div class="col-sm-9">
                                                            <div class="progress">
                                                                <div class="progress-bar" role="progressbar"
                                                                    aria-valuenow="0" aria-valuemin="0"
                                                                    aria-valuemax="100" style="width:0%"
                                                                    :id="'persennya' + (counter + 1)">
                                                                    <span
                                                                        :id="'tulisanpersen' + (counter + 1)">0%</span>
                                                                </div>
                                                            </div>
                                                            <br>
                                                            <span class="text-success"
                                                                :id="'suksesnya' + (counter + 1)"></span>
                                                        </div>
                                                        <div class="col-sm-3 text-center">
                                                            <button type="button"
                                                                class="btn btn-warning btn-sm lebar text-white"
                                                                @click="uploadfile('fileToUploadfilenya_berita' + (counter + 1), (counter + 1))">Upload
                                                                File</button>
                                                            <button type="button" class="btn btn-danger btn-sm lebar"
                                                                @click="clearfilepeta('filenya_berita' + (counter + 1), (counter + 1))">Hapus
                                                                File</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!--IKON PETA 1 END-->
                                            </div>
                                        </div>
                                        <div class="col-sm-12">&nbsp;</div>
                                        <div class="col-sm-12">
                                            <label>Isi Konten</label>
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <quill-editor v-model:value="konten"
                                                        style="height: 25vh;"></quill-editor>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">&nbsp;</div>
                                    </div>
                                </div>
                                <div class="card-footer p-3">
                                    <button type="button" class="btn btn-sm btn-success3 float-left text-white lebar2"
                                        @click="simpan()" v-if="(secretencData == 'Tambah')" data-toggle="tooltip"
                                        data-placement="left" title="Simpan data.."><i class="fas fa-plus-square"></i>
                                        Simpan</button>
                                    <button type="button" class="btn btn-sm btn-success3 float-left text-white lebar2"
                                        v-if="(secretencData != 'Tambah')" @click="update()" data-toggle="tooltip"
                                        data-placement="left" title="Update data.."><i class="fa fa-check-circle"></i>
                                        Update</button>
                                    <button type="button" class="btn btn-sm btn-danger3 float-left text-white lebar2"
                                        @click="batalproses()" data-toggle="tooltip" data-placement="left"
                                        title="Batalkan proses.."><i class="fas fa-window-close"></i> Batal</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid">&nbsp;</div>
        <div class="container-fluid">&nbsp;</div>
        <Footer />
    </div>
</template>

<script>
import $ from 'jquery';
import axios from 'axios';
import vSelect from 'vue-select';
import swal from 'sweetalert2';
import CryptoJS from 'crypto-js';
import { useRoute } from 'vue-router';
import { watch } from 'vue';
import { quillEditor } from 'vue3-quill';
import Footer from "../../componen/Footer.vue";
import NavBar from "../../componen/NavBar.vue";
import Menu from "../../componen/Menu.vue";

import VueApexCharts from "vue3-apexcharts";
window.Apex.chart = { fontFamily: "Poppins-SemiBold, Arial, sans-serif" };
export default {
    setup() {
        const route = useRoute()
        watch(() => route.name, () => {
        });

        return { route };
    },
    components: {
        Footer, NavBar, Menu, vSelect, swal, quillEditor
    },
    data() {
        return {
            halamanloading: true,
            iduser: JSON.parse(localStorage.getItem("usistem")),
            leveluser: JSON.parse(localStorage.getItem("lsistem")),
            namauser: JSON.parse(localStorage.getItem("nmusistem")),
            secretencData: decodeURIComponent(CryptoJS.AES.decrypt(this.route.params.id, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString(CryptoJS.enc.Utf8)),
            tanggal: '',
            nama: '',
            nama_eng: '',
            filenya_berita: '',
            konten: '',
            konten_eng: '',
            koodinatsetujus: [
                {
                    previous: '',
                    expiration: '',
                    filenya_berita: '',
                }
            ],
            validasi: false,
            counter: 0,
        }
    },
    methods: {
        lihatlampiran(filenya) {
            this.halamanloading = true;
            var temp = filenya.split('.');
            document.getElementById('iframefile').src = "";
            if (temp[1].toUpperCase() == 'MP4') {
                document.getElementById('iframefile').style.display = "none";
                document.getElementById('framevideo').style.display = "block";
                document.getElementById('framevideo').src = process.env.VUE_APP_URL_API + '/uploadedfile/filenya_berita/' + filenya;
            }
            else {
                document.getElementById('iframefile').style.display = "block";
                document.getElementById('framevideo').style.display = "none";
                document.getElementById('iframefile').src = process.env.VUE_APP_URL_API + '/uploadedfile/filenya_berita/?f=' + filenya;
            }
            //alert(process.env.VUE_APP_URL_API + foldernya + '?f=' + filenya);

            $('#costumModaldisclaimer').modal('show');
            this.halamanloading = false;
        },
        addKoordinat() {
            this.koodinatsetujus.push({
                previous: '',
                expiration: '',
                filenya_berita: '',
            })
        },
        deleteKoordinat() {
            this.koodinatsetujus.splice(-1);
        },
        initialize() {
            //Initialize Select2 Elements
            $(".select2").select2();

            //Initialize Select2 Elements
            $(".select2bs4").select2({
                theme: "bootstrap4",
            });
            $('[data-mask]').inputmask();
            //$('#summernote').summernote();
        },
        getOtentifikasi() {
            var time = Date.parse(localStorage.getItem("tsistem"));
            var timeakhir = time + (3 * 60 * 60 * 1000);
            var timesekarang = Date.parse(new Date());
            if (timesekarang > timeakhir) {
                this.ClearlocalStorage();
            }
            this.halamanloading = false;
        },
        ontabelChange(a) {
            try {
                this.selectedtabel = this.tabel[a].tag;
            }
            catch (err) {
                return;
            }
        },
        batalproses() {
            window.location.replace("/Berita");
        },
        uploadfile(idnya, kodenya) {
            var elmnt = document.getElementById(idnya);
            try {
                //var namanya = elmnt.files[0].name;
                //alert(elmnt.files[0]['type'].toUpperCase());
                //return false;
                if ((elmnt.files[0]['type'].toUpperCase() != 'IMAGE/JPG') && (elmnt.files[0]['type'].toUpperCase() != 'IMAGE/JPEG') && (elmnt.files[0]['type'].toUpperCase() != 'IMAGE/PNG') && (elmnt.files[0]['type'].toUpperCase() != 'IMAGE/GIF') && (elmnt.files[0]['type'].toUpperCase() != 'VIDEO/MP4')) {
                    document.getElementById('suksesnya' + kodenya).innerHTML = '*file harus format gambar / video';
                    return;
                }
            } catch (error) {
                document.getElementById('suksesnya' + kodenya).innerHTML = '*pilih file terlebih dahulu';
                return;
            }
            var fd = new FormData();
            fd.append("fileToUpload", elmnt.files[0], elmnt.files[0].name);
            fd.append("folderToUpload", 'filenya_berita');
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            mainAPI.post("parastapainnovation-FilesUpload", fd, {
                onUploadProgress: function (uploadEvent) {
                    document.getElementById('persennya' + kodenya).style.width = Math.round((uploadEvent.loaded / uploadEvent.total) * 100) + "%";
                    document.getElementById('tulisanpersen' + kodenya).innerHTML = Math.round((uploadEvent.loaded / uploadEvent.total) * 100) + "%";
                }
            }).then(
                function (res) {
                    document.getElementById('suksesnya' + kodenya).innerHTML = res.data.message;
                    if (res.data.filename != undefined) {
                        document.getElementById('filenya_berita' + kodenya).value = res.data.filename;
                        document.getElementById('templabelfilenya_berita' + kodenya).innerHTML = res.data.filename;
                    }
                }
            ).catch(function (e) {
                document.getElementById('suksesnya' + kodenya).innerHTML = res.data.message;
                document.getElementById('filenya_berita' + kodenya).value = '';
                document.getElementById('templabelfilenya_berita' + kodenya).innerHTML = '';
                console.log(e);
            }
            )
        },
        clearfilepeta(idnya, kodenya) {
            if (document.getElementById(idnya).value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'File Tidak Tersedia',
                });
                return false;
            }

            var fd = new FormData();
            fd.append("folderToUpload", 'filenya_berita');
            fd.append("filenya", document.getElementById(idnya).value);
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            mainAPI.post("parastapainnovation-FilesDeleted", fd).then(
                Response => {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: Response.data.message,
                    });
                    document.getElementById(idnya).value == '';
                }
            ).catch(function (error) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: error
                });
            }
            )

            document.getElementById('fileToUpload' + idnya).value = '';
            document.getElementById(idnya).value = '';
            document.getElementById('persennya' + kodenya).style.width = "0%";
            document.getElementById('tulisanpersen' + kodenya).innerHTML = "0%";
            document.getElementById('suksesnya' + kodenya).innerHTML = '';
            document.getElementById('templabelfilenya_berita' + kodenya).innerHTML = '';
            return false;
        },
        async AmbilDataSingel() {
            this.halamanloading = true;
            var mainAPIDataSingel = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPIDataSingel.get("parastapainnovation_berita-GetBeritaByID?id=" + this.secretencData + "&random=" + random).then(
                Response => {
                    this.tanggal = Response.data.content.data[0].tanggal;
                    this.nama = Response.data.content.data[0].nama;
                    this.nama_eng = Response.data.content.data[0].nama_eng;
                    this.konten = atob(Response.data.content.data[0].konten);
                    this.konten_eng = atob(Response.data.content.data[0].konten_eng);
                    this.filenya_berita = Response.data.content.data[0].filenya_berita;
                    //alert(this.filenya_berita);
                    var myArrayGambar = this.filenya_berita.split("|");
                    //alert(myArrayGambar.length);
                    this.koodinatsetujus = [];
                    myArrayGambar.forEach((item) => {
                        this.koodinatsetujus.push({
                            previous: '',
                            expiration: '',
                            filenya_berita: item,
                        })
                    });
                    //alert(JSON.stringify(myArrayGambar));
                    //alert(JSON.stringify(this.koodinatsetujus));
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })
        },
        validasidata() {
            if (document.getElementById('tanggal').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('tanggal').focus();
                return false;
            }
            if (document.getElementById('nama').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('nama').focus();
                return false;
            }
            if (this.konten == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Konten',
                });
                return false;
            }
            this.validasi = true;
            return;
        },
        simpan() {
            this.validasidata();
            if (this.validasi == false) {
                return false;
            }
            var filenya_beritasimpan = '';
            for (let i = 1; i <= this.koodinatsetujus.length; i++) {
                if (document.getElementById('templabelfilenya_berita' + i).innerHTML == '') {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: 'File ke- ' + i + 'Belum Terisi',
                    });
                    return false;
                }
                if (i == 1) {
                    filenya_beritasimpan = filenya_beritasimpan + document.getElementById('templabelfilenya_berita' + i).innerHTML;
                }
                else {
                    filenya_beritasimpan = filenya_beritasimpan + '|' + document.getElementById('templabelfilenya_berita' + i).innerHTML;
                }
            }
            var fd = new FormData();
            fd.append("tanggal", this.tanggal);
            fd.append("nama", this.nama);
            fd.append("nama_eng", this.nama_eng);
            fd.append("konten", this.konten);
            fd.append("konten_eng", this.konten_eng);
            fd.append("filenya_berita", filenya_beritasimpan);
            fd.append("createdby", JSON.parse(localStorage.getItem("usistem")));

            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            mainAPI.post("parastapainnovation_berita-AddBerita", fd).then(
                Response => {
                    if (Response.data.response == 'error') {
                        swal.fire('Peringatan', Response.data.message, 'error');
                    }
                    else {
                        swal.fire('Informasi', 'Data telah tersimpan..', 'success').then(function () {
                            window.location.replace("/Berita");
                        });
                    }
                }
            ).catch(function (error) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: error
                });
            }
            )
        },
        update() {
            this.validasidata();
            if (this.validasi == false) {
                return false;
            }
            var filenya_beritasimpan = '';
            for (let i = 1; i <= this.koodinatsetujus.length; i++) {
                if (document.getElementById('templabelfilenya_berita' + i).innerHTML == '') {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: 'File ke- ' + i + 'Belum Terisi',
                    });
                    return false;
                }
                if (i == 1) {
                    filenya_beritasimpan = filenya_beritasimpan + document.getElementById('templabelfilenya_berita' + i).innerHTML;
                }
                else {
                    filenya_beritasimpan = filenya_beritasimpan + '|' + document.getElementById('templabelfilenya_berita' + i).innerHTML;
                }
            }
            var fd = new FormData();
            fd.append("tanggal", this.tanggal);
            fd.append("nama", this.nama);
            fd.append("nama_eng", this.nama_eng);
            fd.append("konten", this.konten);
            fd.append("konten_eng", this.konten_eng);
            fd.append("filenya_berita", filenya_beritasimpan);
            fd.append("updatedby", JSON.parse(localStorage.getItem("usistem")));
            fd.append("id", this.secretencData);
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            mainAPI.post("parastapainnovation_berita-UpdateBerita", fd).then(
                Response => {
                    if (Response.data.response == 'error') {
                        swal.fire('Peringatan', Response.data.message, 'error');
                    }
                    else {
                        swal.fire('Informasi', 'Data telah terupdate..', 'success').then(function () {
                            window.location.replace("/Berita");
                        });
                    }
                }
            ).catch(function (error) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: error
                });
            }
            )
            return false;
        },
    },
    mounted() {
        this.getOtentifikasi();
        this.initialize();
        if (this.secretencData != 'Tambah') {
            this.AmbilDataSingel();
        }
    }
}
</script>
<style></style>