<template>
    <Top />
    <!-- Spinner Start -->
    <div id="spinner" v-if="halamanloading"
        class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        &nbsp;<img src="../../../../src/assets/img/BerandaNgumahLogo.png" height="40">
        &nbsp;<span class="text-danger">..Loading...</span>
    </div>
    <!-- Spinner End -->
    <!-- Header Start -->
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center py-5" style="max-width: 900px;">
            <h3 class="text-white display-3 mb-4 wow fadeInDown pmisputih" data-wow-delay="0.1s">
                <span v-if="(bahasa === null) || (bahasa == 'ina')">Daftar Penyusunan & Penetapan Rencana Tata
                    Ruang</span>
                <span v-else>Daftar Penyusunan & Penetapan Rencana Tata Ruang</span>
            </h3>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s">
                <li class="breadcrumb-item">
                    <a href="/">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Beranda</span>
                        <span v-else>Home</span>
                    </a>
                </li>
                <li class="breadcrumb-item active text-primary">
                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Penyusunan & Penetapan Rencana Tata Ruang</span>
                    <span v-else>Penyusunan & Penetapan Rencana Tata Ruang</span>
                </li>
            </ol>
        </div>
    </div>
    <!-- Header End -->
    <!-- Contact Start -->
    <div class="container-fluid blog py-2">&nbsp;</div>
    <div class="container-fluid bg-breadcrumbberanda wow fadeInDown">
        <div class="section-title mb-2 wow fadeInDown" data-wow-delay="0.1s">
            <div class="col-lg-12 col-xl-12 wow fadeInRight" data-wow-delay="0.3s">
                <ul class="nav nav-tabs justify-content-center" id="custom-tabs-four-tab" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" id="custom-tabs-four-home-tab" data-toggle="pill"
                            href="#custom-tabs-four-home" role="tab" aria-controls="custom-tabs-four-home"
                            aria-selected="true">
                            RTRW
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="custom-tabs-four-profile-tab" data-toggle="pill"
                            href="#custom-tabs-four-profile" role="tab" aria-controls="custom-tabs-four-profile"
                            aria-selected="false">
                            RDTR
                        </a>
                    </li>
                </ul>

            </div>
        </div>
        <div class="card-body">
            <div class="tab-content" id="custom-tabs-four-tabContent">
                <div class="tab-pane fade show active" id="custom-tabs-four-home" role="tabpanel"
                    aria-labelledby="custom-tabs-four-home-tab">
                    <div class="container-fluid feature py-3">
                        <div class="section-title mb-0 wow fadeInUp" data-wow-delay="0.1s">
                            <div class="sub-style">
                                <h4 class="sub-title px-3 mb-0">
                                    RTRW
                                </h4>
                            </div>
                        </div>
                    </div>
                    <div class="row px-2">
                        <div class="col-sm-12 wow fadeInUp" data-wow-delay="0.1s">
                            <div class="table-responsive table-wrapper">
                                <table class="table m-0" id="tabelinvestasi">
                                    <thead>
                                        <tr>
                                            <td rowspan="2">No</td>
                                            <td rowspan="2">Kabupaten/Kota</td>
                                            <td rowspan="2" width="150px">Revisi & PK</td>
                                            <td colspan="2" width="150px">Tahapan di Prov</td>
                                            <td rowspan="2" width="150px">Proses Persub ATR<br>(Sudah Terbit<br>Rekom
                                                Gub/BA<br>TKPRD Prov)
                                            </td>
                                            <td rowspan="2" width="150px">Proses Evaluasi Gub<br>(Sudah Terbit<br>Persub
                                                AT)</td>
                                            <td rowspan="2" width="300px">Perda Baru</td>
                                        </tr>
                                        <tr>
                                            <td>Rekom Gub<br>(Sebelum UU CK)</td>
                                            <td>BA TKPRD Prov<br>(Pasca UU CK)</td>
                                        </tr>
                                    </thead>
                                    <template v-for="(datalist, urutlist) in datamaster" :key="urutlist">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    {{ urutlist + 1 }}
                                                </td>
                                                <td>
                                                    {{ datalist.NAMA }}
                                                </td>
                                                <td class="text-center">
                                                    <span v-if="datalist.revis_pk == 1">V</span>
                                                    <span v-else>X</span>
                                                </td>
                                                <td class="text-center">
                                                    <span v-if="datalist.rekom_gub == 1">V</span>
                                                    <span v-else>X</span>
                                                </td>
                                                <td class="text-center">
                                                    <span v-if="datalist.ba_tkprd == 1">V</span>
                                                    <span v-else>X</span>
                                                </td>
                                                <td class="text-center">
                                                    <span v-if="datalist.proses_atr == 1">V</span>
                                                    <span v-else>X</span>
                                                </td>
                                                <td class="text-center">
                                                    <span v-if="datalist.evaluasi_gub == 1">V</span>
                                                    <span v-else>X</span>
                                                </td>
                                                <td>
                                                    {{ datalist.perdabaru }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="tab-pane fade" id="custom-tabs-four-profile" role="tabpanel"
                    aria-labelledby="custom-tabs-four-profile-tab">
                    <div class="container-fluid feature py-3">
                        <div class="section-title mb-0 wow fadeInUp" data-wow-delay="0.1s">
                            <div class="sub-style">
                                <h4 class="sub-title px-3 mb-0">
                                    RDTR
                                </h4>
                            </div>
                        </div>
                    </div>
                    <div class="row px-2">
                        <div class="col-sm-12">
                            <div class="table-responsive table-wrapper">
                                <table class="table m-0" id="tabelinvestasi">
                                    <thead>
                                        <tr>
                                            <td rowspan="2">No</td>
                                            <td rowspan="2">Kabupaten/Kota</td>
                                            <td rowspan="2" width="150px">RDTR</td>
                                            <td rowspan="2" width="150px">Proses</td>
                                            <td colspan="2" width="150px">Keterangan</td>
                                        </tr>
                                    </thead>
                                    <template v-for="(datalistrdtr, urutlistrdtr) in datamasterrdtr" :key="urutlistrdtr">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    {{ urutlistrdtr + 1 }}
                                                </td>
                                                <td>
                                                    {{ datalistrdtr.NAMA }}
                                                </td>
                                                <td>
                                                    {{ datalistrdtr.namardtr }}
                                                </td>
                                                <td>
                                                    {{ datalistrdtr.namaproses }}
                                                </td>
                                                <td>
                                                    {{ datalistrdtr.keterangan }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <!-- Contact End -->
    <a href="#" class="btn btn-dangerbulet btn-lg-square back-to-top text-white"><i class="fa fa-arrow-up"></i></a>
    <Footer />
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import swal from 'sweetalert2';

import Top from "./Template/Top.vue";
import Footer from "./Template/Footer.vue";

export default {
    components: {
        Top, Footer, swal
    },
    data() {
        return {
            halamanloading: true,
            tahun: new Date().getFullYear(),
            bahasa: localStorage.getItem("bahasasistem"),
            sourcenya: '',
            datapesan: '',
            datamaster: [],
            datamasterrdtr: [],
        };
    },
    methods: {
        async loadrtrw() {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });

            this.halamanloading = true;
            var random = Math.random();
            await mainAPI.get("parastapainnovationproses-GetRTRW?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datamaster = Response.data.content.data;
                    }
                    else {
                        this.datapesan = [];
                        this.datamaster = [];
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = [];
                this.datamaster = [];

                this.halamanloading = false;
            });
        },
        async loadrdtr() {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });

            this.halamanloading = true;
            var random = Math.random();
            await mainAPI.get("parastapainnovationproses-GetRDTR?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datamasterrdtr = Response.data.content.data;
                    }
                    else {
                        this.datapesan = [];
                        this.datamasterrdtr = [];
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = [];
                this.datamasterrdtr = [];

                this.halamanloading = false;
            });
        },
    },
    mounted() {
        this.loadrtrw();
        this.loadrdtr();
    },
}
</script>

<style></style>