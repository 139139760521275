<template>
    <Top />
    <!-- Spinner Start -->
    <div id="spinner" v-if="halamanloading"
        class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        &nbsp;<img src="../../../../src/assets/img/BerandaNgumahLogo.png" height="40">
        &nbsp;<span class="text-danger">..Loading...</span>
    </div>
    <!-- Spinner End -->
    <div class="content-header">
        <div class="col-sm-12">&nbsp;</div>
        <div class="col-sm-12">&nbsp;</div>
        <div class="col-sm-12">&nbsp;</div>
        <div class="col-sm-12">&nbsp;</div>
    </div>
    <div class="col-sm-12">&nbsp;</div>
    <div class="lockscreen-logo">
        <img src="../../../../src/assets/img/BerandaNgumahLogo.png" alt="AdminLTE Logo" class="wow fadeInDown"
            style="opacity: .8;height: 135px;">
    </div>
    <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-4" id="peringatan" style="display: none;">
            <div class="card bg-danger">
                <div class="card-header">
                    <h3 class="card-title">Peringatan</h3>
                </div>
                <div class="card-body text-center" id="labelperingatan">
                </div>
                <!-- /.card-body -->
            </div>
        </div>
        <div class="col-md-4"></div>
    </div>
    <div class="col-sm-12">&nbsp;</div>
    <!-- START LOCK SCREEN ITEM -->
    <div class="lockscreen-item wow fadeInLeft">
        <!-- lockscreen image -->
        <div class="lockscreen-image">
            <img src="../../../assets/img/3177440.png" alt="User Image">
        </div>
        <!-- /.lockscreen-image -->

        <!-- lockscreen credentials (contains the form) -->
        <div class="lockscreen-credentials">
            <div class="input-group">
                <input type="text" class="form-control" placeholder="username" id="username" :isFilled="true"
                    v-model="username">

                <div class="input-group-append">
                    <button type="button" class="btn">
                        <i class="fas fa-arrow-right text-muted"></i>
                    </button>
                </div>
            </div>
        </div>
        <!-- /.lockscreen credentials -->

    </div>
    <!-- /.lockscreen-item -->
    <!-- User name -->
    <div class="lockscreen-name">&nbsp;</div>

    <!-- START LOCK SCREEN ITEM -->
    <div class="lockscreen-item wow fadeInLeft">
        <!-- lockscreen image -->
        <div class="lockscreen-image">
            <img src="../../../assets/img/4992489.png" alt="User Image">
        </div>
        <!-- /.lockscreen-image -->

        <!-- lockscreen credentials (contains the form) -->
        <div class="lockscreen-credentials">
            <div class="input-group">
                <input :type="[showPassword ? 'text' : 'password']" class="form-control" placeholder="password"
                    id="password" :isFilled="true" v-model="password">

                <div class="input-group-append">
                    <button type="button" class="btn" @click="toggleShow">
                        <i
                            :class="{ 'fas fa-eye-slash text-muted': showPassword, 'fas fa-eye text-muted': !showPassword }"></i>
                    </button>
                </div>
            </div>
        </div>
        <!-- /.lockscreen credentials -->

    </div>
    <div class="lockscreen-item wow fadeInLeft" style="background: transparent;">
        <div class="input-group">
            <div class="custom-control custom-switch custom-switch-on-primary">
                <input type="checkbox" class="custom-control-input" id="customSwitchAuth">
                <label class="custom-control-label" for="customSwitchAuth" @click="rubahisi()"
                    style="font-size: small;">
                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Autentikasi Pengguna Manusia</span>
                    <span v-else>Human User Authentication</span>
                </label>
            </div>
        </div>
        <div class="help-block text-center">&nbsp;</div>
        <button class="btn btn-danger2 btn-block text-white" placeholder="Username" data-toggle="tooltip"
            data-placement="left" title="Masuk ke Halaman Admin.." style="color: black;" v-on:click="loginAct"
            :disabled="showHuman == false">
            <b><i class="fas fa-sign-in-alt"></i>
                <span v-if="(bahasa === null) || (bahasa == 'ina')"> Masuk</span>
                <span v-else> Login</span>
            </b>
        </button>
        <button class="btn btn-danger2 btn-block text-white" placeholder="Username" data-toggle="tooltip"
            data-placement="left" title="SSO ke Halaman Admin.." style="color: black;display: none;" v-on:click="loginActSSO">
            <b><i class="fas fa-sign-in-alt"></i>
                <span v-if="(bahasa === null) || (bahasa == 'ina')"> SSO</span>
                <span v-else> SSO</span>
            </b>
        </button>
    </div>
    <!-- /.lockscreen-item -->
    <div class="help-block text-center wow fadeInLeft">
        <span v-if="(bahasa === null) || (bahasa == 'ina')"> Silahkan masukkan user dan kata kunci untuk masuk halaman
            data</span>
        <span v-else> Enter your user & password to retrieve your session</span>
    </div>
    <div class="help-block text-center">&nbsp;</div>
    <div class="content-header">
        <div class="col-sm-12">&nbsp;</div>
    </div>
    <a href="#" class="btn btn-dangerbulet btn-lg-square back-to-top text-white"><i class="fa fa-arrow-up"></i></a>
    <Footer />
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import swal from 'sweetalert2';

import Top from "./Template/Top.vue";
import Footer from "./Template/Footer.vue";

export default {
    components: {
        Top, Footer, swal
    },
    data() {
        return {
            halamanloading: true,
            tahun: new Date().getFullYear(),
            username: "", password: "",
            showHuman: false,
            showPassword: false,
            bahasa: localStorage.getItem("bahasasistem"),
            client_id: 'app-db-005',
            secret_key: 'VMkuMWlu3btNCCm5RmExg2l1ZuLKeax5PwBSQuYPIxFEW9cgT3lUnYTMxUSGd7eB9mMqMq0fPsAKCMbwJkY8WUPkDvlVz2jdWBoq6QRDxjO',
        };
    },
    methods: {
        toggleShow() {
            this.showPassword = !this.showPassword;
        },
        rubahisi() {
            if (this.showHuman == false) {
                this.showHuman = true;
            }
            else {
                this.showHuman = false;
            }
        },
        async loginActSSO() {
            const data = {
                client_id: this.client_id,
                secret_key: this.secret_key
            };
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer mP4sSWYmdK9EUEXYbO8UaEn01q37YI8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            });
            await mainAPI.post("parastapainnovation-sso", data).then(
                Response => {
                    if (Response.data.response == 'error') {
                        swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000
                        }).fire({
                            icon: 'warning',
                            title: 'Gagal melakukan SSO',
                        });
                        this.showHuman = false;
                        document.getElementById('customSwitchAuth').checked = false;
                        return false;

                    }
                    else {
                        //alert(JSON.stringify(Response.data.accessToken));
                        //return false;
                        if (Response.data.accessToken != "VMkuMWlu3btNCCm5RmExg2l1ZuLKeax5PwBSQuYPIxFEW9cgT3lUnYTMxUSGd7eB9mMqMq0fPsAKCMbwJkY8WUPkDvlVz2jdWBoq6QRDxjO") {
                            swal.mixin({
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 3000
                            }).fire({
                                icon: 'warning',
                                title: 'Gagal masuk SSO',
                            });
                        }
                        else {
                            localStorage.setItem("tokensistem", JSON.stringify("17c4520f6cfd1ab53d8745e84681eb49"));
                            localStorage.setItem("usistem", JSON.stringify("c4ca4238a0b923820dcc509a6f75849b"));
                            localStorage.setItem("lsistem", JSON.stringify("1"));
                            localStorage.setItem("nmlsistem", JSON.stringify("ssologin"));
                            localStorage.setItem("emlsistem", JSON.stringify("ssologin"));
                            localStorage.setItem("nmusistem", JSON.stringify("admin sso"));
                            localStorage.setItem("orsistem", JSON.stringify("DINAS PEKERJAAN UMUM SUMBER DAYA AIR DAN PENATAAN RUANG"));
                            localStorage.setItem("nmorsistem", JSON.stringify("DINAS PEKERJAAN UMUM SUMBER DAYA AIR DAN PENATAAN RUANG"));
                            localStorage.setItem("bdlsistem", JSON.stringify("DINAS PEKERJAAN UMUM SUMBER DAYA AIR DAN PENATAAN RUANG"));
                            localStorage.setItem("nmbdlsistem", JSON.stringify("DINAS PEKERJAAN UMUM SUMBER DAYA AIR DAN PENATAAN RUANG"));
                            localStorage.setItem("sbdsistem", JSON.stringify("DINAS PEKERJAAN UMUM SUMBER DAYA AIR DAN PENATAAN RUANG"));
                            localStorage.setItem("nmsbdsistem", JSON.stringify("DINAS PEKERJAAN UMUM SUMBER DAYA AIR DAN PENATAAN RUANG"));
                            localStorage.setItem("jbtsistem", JSON.stringify("DINAS PEKERJAAN UMUM SUMBER DAYA AIR DAN PENATAAN RUANG"));
                            localStorage.setItem("loksistem", JSON.stringify("33"));
                            localStorage.setItem("nmloksistem", JSON.stringify("Provinsi Jawa Tengah"));
                            localStorage.setItem("imgsistem", JSON.stringify("3177440.png"));
                            localStorage.setItem("tsistem", new Date().toString());
                            window.location.replace("/Dashboard");
                        }
                    }
                }
            ).catch(
                error => {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: error,
                    });
                    return false;
                }
            )
        },
        async loginAct() {
            const data = {
                username: this.username,
                katakunci: this.password
            };
            if ((this.username == "") || (this.password == "")) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Isikan kolom secara lengkap..',
                });
                document.getElementById('customSwitchAuth').checked = false;
                document.getElementById("username").focus();
                this.showHuman = false;
                return false;
            }
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            });
            await mainAPI.post("parastapainnovation-login", data).then(
                Response => {
                    if (Response.data.response == 'error') {
                        swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000
                        }).fire({
                            icon: 'warning',
                            title: Response.data.message,
                        });
                        this.showHuman = false;
                        document.getElementById('customSwitchAuth').checked = false;
                        return false;

                    }
                    else {
                        const mainAPIUser = axios.create({
                            baseURL: process.env.VUE_APP_URL_API,
                            headers: {
                                Authorization: "Bearer " + Response.data.accessToken,
                            },
                        });
                        mainAPIUser.get("parastapainnovationUser-GetUser?kduser=" + Response.data.content.data[0].tmpsys + "&level=" + Response.data.content.data[0].level).then(
                            Res => {
                                if (Res.data.response == 'error') {
                                    swal.mixin({
                                        toast: true,
                                        position: 'top-end',
                                        showConfirmButton: false,
                                        timer: 3000
                                    }).fire({
                                        icon: 'warning',
                                        title: Res.data.message,
                                    });
                                    document.getElementById("username").focus();
                                    this.showHuman = false;
                                    document.getElementById('customSwitchAuth').checked = false;
                                    return false;

                                }
                                else {
                                    localStorage.setItem("tokensistem", JSON.stringify(Response.data.accessToken));
                                    localStorage.setItem("usistem", JSON.stringify(Response.data.content.data[0].tmpsys));
                                    localStorage.setItem("lsistem", JSON.stringify(Res.data.content.data[0].level));
                                    localStorage.setItem("nmlsistem", JSON.stringify(Res.data.content.data[0].namaleveluser));
                                    localStorage.setItem("emlsistem", JSON.stringify(Res.data.content.data[0].email));
                                    localStorage.setItem("nmusistem", JSON.stringify(Res.data.content.data[0].nama));
                                    localStorage.setItem("orsistem", JSON.stringify(Res.data.content.data[0].organisasi));
                                    localStorage.setItem("nmorsistem", JSON.stringify(Res.data.content.data[0].namaorganisasi));
                                    localStorage.setItem("bdlsistem", JSON.stringify(Res.data.content.data[0].bidang));
                                    localStorage.setItem("nmbdlsistem", JSON.stringify(Res.data.content.data[0].namabidang));
                                    localStorage.setItem("sbdsistem", JSON.stringify(Res.data.content.data[0].subbidang));
                                    localStorage.setItem("nmsbdsistem", JSON.stringify(Res.data.content.data[0].namasubbidang));
                                    localStorage.setItem("jbtsistem", JSON.stringify(Res.data.content.data[0].jabatan));
                                    localStorage.setItem("loksistem", JSON.stringify(Res.data.content.data[0].lokasi));
                                    localStorage.setItem("nmloksistem", JSON.stringify(Res.data.content.data[0].namalokasi));
                                    localStorage.setItem("imgsistem", JSON.stringify(Res.data.content.data[0].foto));
                                    localStorage.setItem("tsistem", new Date().toString());
                                    window.location.replace("/Dashboard");
                                }
                            }
                        ).catch(
                            error => {
                                swal.mixin({
                                    toast: true,
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 3000
                                }).fire({
                                    icon: 'warning',
                                    title: error,
                                });
                                this.showHuman = false;
                                document.getElementById('customSwitchAuth').checked = false;
                                return false;
                            }
                        )
                    }
                }
            ).catch(
                error => {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: error,
                    });
                    this.showHuman = false;
                    document.getElementById('customSwitchAuth').checked = false;
                    return false;
                }
            )
        },
    },
    mounted() {
        try {
            document.getElementById("username").focus();
            document.getElementById('customSwitchAuth').checked = false;
            this.showHuman = false;
            this.showPassword = false;
            this.JustClearlocalStorage();
        } catch (error) {

        }
        this.halamanloading = false;
    },
}
</script>

<style></style>