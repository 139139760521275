<template>
    <Top />
    <!-- Spinner Start -->
    <div id="spinner" v-if="halamanloading"
        class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        &nbsp;<img src="../../../../src/assets/img/BerandaNgumahLogo.png" height="40">
        &nbsp;<span class="text-danger">..Loading...</span>
    </div>
    <!-- Spinner End -->
    <!-- Header Start -->
    <div id="myModal" class="modal">
        <span class="close">&times;</span>
        <img class="modal-content" id="img01">
        <div id="caption"></div>
    </div>
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center py-5" style="max-width: 900px;">
            <h3 class="text-white display-3 mb-4 wow fadeInDown pmisputih" data-wow-delay="0.1s">
                <span v-if="(bahasa === null) || (bahasa == 'ina')">PUSTAKA TATA RUANG</span>
                <span v-else>PUSTAKA TATA RUANG</span>
            </h3>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s">
                <li class="breadcrumb-item">
                    <a href="/">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Beranda</span>
                        <span v-else>Home</span>
                    </a>
                </li>
                <li class="breadcrumb-item active text-primary">
                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Pustaka Tata Ruang</span>
                    <span v-else>Pustaka Tata Ruang</span>
                </li>
            </ol>
        </div>
    </div>
    <!-- Header End -->

    <div class="container-fluid py-3">
        <div class="container py-3">
            <div class="row g-4 justify-content-center">
                <div class="col-sm-12 float-left pb-2 px-3 wow fadeInDown">
                    Jumlah Data Pustaka: {{ G_numFormat(jumlahdata) }}
                    <span style="float: right;">
                        Halaman ke: <select v-model="halaman" @change="onChangeHalaman($event)">
                            <option v-for="index in jumlahhalaman" :key="index">
                                {{ index }}</option>
                        </select> dari {{ jumlahhalaman }}
                    </span>
                </div>
                <div class="col-sm-11 float-left wow fadeInDown">
                    <input type="text" v-model="carikata" class="form-control form-control-sm"
                        placeholder="Masukkan Kata Kunci Pencarian">
                </div>
                <div class="col-sm-1 float-left wow fadeInDown">
                    <button type="button" class="btn btn-success3 btn-sm text-white" @click="caridata()"
                        data-toggle="tooltip" data-placement="left" title="Cari data..">
                        <i class="fas fa-search"></i>
                    </button>
                </div>
                <div class="col-md-6 col-lg-4 col-xl-3 wow fadeInUp" data-wow-delay="0.1s"
                    v-for="(beranda, urutberanda) in databeranda" :key="urutberanda">
                    <div class="service-item rounded">
                        <div class="service-img rounded-top text-center">
                            <a :href="folder_file + beranda.filenya_pustakapdf" class="mb-4">{{ beranda.nama }}</a>
                            <img :id="beranda.id" :src="folder_foto + beranda.filenya_pustaka"
                                class="img-fluid rounded-top w-100 gambar" :alt="beranda.konten_eng"
                                @click="ambilgambar(beranda.id)">
                        </div>
                        <div class="service-content rounded-bottom bg-light p-4">
                            <div class="service-content-inner text-center">
                                <a :href="folder_file + beranda.filenya_pustakapdf"
                                    class="btn btn-primary rounded-pill text-white py-2 px-4 mb-2">Unduh </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <a href="#" class="btn btn-dangerbulet btn-lg-square back-to-top text-white"><i class="fa fa-arrow-up"></i></a>
    <Footer />
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import swal from 'sweetalert2';
import CryptoJS from 'crypto-js';
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'

import Top from "./Template/Top.vue";
import Footer from "./Template/Footer.vue";

export default {
    components: {
        Top, Footer, swal, Carousel, Slide, Pagination, Navigation,
    },
    data() {
        return {
            halamanloading: true,
            tabaktif: encodeURIComponent(CryptoJS.AES.encrypt('', 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString()),
            datapesan: '',
            datamaster: [],
            tahun: new Date().getFullYear(),
            slides: [],
            breakpoints: {
                // 700px and up
                700: {
                    itemsToShow: 1,
                    snapAlign: 'center',
                },
                // 1024 and up
                1024: {
                    itemsToShow: 1,
                    snapAlign: 'start',
                },
            },
            bahasa: localStorage.getItem("bahasasistem"),
            databeranda: [],
            folder_foto: process.env.VUE_APP_URL_API + 'parastapainnovation_pustaka-Gambar?f=',
            folder_file: process.env.VUE_APP_URL_API + 'parastapainnovation_pustaka-PDF?f=',
            jumlahdata: 0,
            jumlahhalaman: 0,
            mulai: 0,
            halaman: 1,
        };
    },
    methods: {
        ambilgambar(id) {
            var modal = document.getElementById("myModal");
            // Get the image and insert it inside the modal - use its "alt" text as a caption
            var img = document.getElementById(id);
            var modalImg = document.getElementById("img01");
            var captionText = document.getElementById("caption");
            modal.style.display = "block";
            modalImg.src = img.src;
            captionText.innerHTML = img.alt;

            // Get the <span> element that closes the modal
            var span = document.getElementsByClassName("close")[0];

            // When the user clicks on <span> (x), close the modal
            span.onclick = function () {
                modal.style.display = "none";
            }
        },
        async caridata() {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });
            //alert(this.carikata);
            this.halamanloading = true;
            var random = Math.random();
            await mainAPI.get("parastapainnovation-GetPustaka?random=" + random + "&nama=" + this.carikata).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        var datasementara = [];
                        this.databeranda = [];
                        datasementara = Response.data.content.data;
                        datasementara.forEach((item) => {
                            this.databeranda.push({
                                id: item.id,
                                nama: item.nama,
                                nama_eng: item.nama_eng,
                                filenya_pustakapdf: item.filenya_pustakapdf,
                                konten_eng: item.nama,
                                filenya_pustaka: item.filenya_pustaka,
                            });
                        });
                        this.jumlahdata = Response.data.content.jumlahdata;
                        this.jumlahhalaman = Response.data.content.jumlahhalaman;
                        this.mulai = Response.data.content.mulai;
                        if (this.mulai == 0) {
                            this.halaman = 1;
                        }
                        else {
                            this.halaman = (this.mulai / 20) + 1;
                        }
                    }
                    else {
                        this.jumlahdata = 0;
                        this.jumlahhalaman = 0;
                        this.mulai = 0;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = [];
                this.datamaster = [];
                this.slides = [];
                console.log(error);
                this.halamanloading = false;
            });
        },
        onChangeHalaman(event) {
            this.pindahhalaman(event.target.value);
        },
        async pindahhalaman(halamannya) {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });

            this.halamanloading = true;
            var random = Math.random();
            await mainAPI.get("parastapainnovation-GetPustaka?random=" + random + "&halaman=" + halamannya + "&nama=" + this.carikata).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        var datasementara = [];
                        this.databeranda = [];
                        datasementara = Response.data.content.data;
                        datasementara.forEach((item) => {
                            this.databeranda.push({
                                id: item.id,
                                nama: item.nama,
                                filenya_pustakapdf: item.filenya_pustakapdf,
                                nama_eng: item.nama_eng,
                                konten_eng: item.nama,
                                filenya_pustaka: item.filenya_pustaka,
                            });
                        });
                        this.jumlahdata = Response.data.content.jumlahdata;
                        this.jumlahhalaman = Response.data.content.jumlahhalaman;
                        this.mulai = Response.data.content.mulai;
                        if (this.mulai == 0) {
                            this.halaman = 1;
                        }
                        else {
                            this.halaman = (this.mulai / 20) + 1;
                        }
                    }
                    else {
                        this.jumlahdata = 0;
                        this.jumlahhalaman = 0;
                        this.mulai = 0;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = [];
                this.datamaster = [];
                this.slides = [];
                console.log(error);
                this.halamanloading = false;
            });
        },
        async ambildata() {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });

            this.halamanloading = true;
            var random = Math.random();
            await mainAPI.get("parastapainnovation-GetPustaka?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        var datasementara = [];
                        this.databeranda = [];
                        datasementara = Response.data.content.data;
                        datasementara.forEach((item) => {
                            this.databeranda.push({
                                id: item.id,
                                nama: item.nama,
                                filenya_pustakapdf: item.filenya_pustakapdf,
                                nama_eng: item.nama_eng,
                                konten_eng: item.nama,
                                filenya_pustaka: item.filenya_pustaka,
                            });
                        });
                        this.jumlahdata = Response.data.content.jumlahdata;
                        this.jumlahhalaman = Response.data.content.jumlahhalaman;
                        this.mulai = Response.data.content.mulai;
                        if (this.mulai == 0) {
                            this.halaman = 1;
                        }
                        else {
                            this.halaman = (this.mulai / 20) + 1;
                        }
                    }
                    else {
                        this.jumlahdata = 0;
                        this.jumlahhalaman = 0;
                        this.mulai = 0;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = [];
                this.datamaster = [];
                this.slides = [];
                console.log(error);
                this.halamanloading = false;
            });
        },
    },
    mounted() {
        this.ambildata();

    },
}
</script>

<style></style>