<template>
    <Top />
    <!-- Spinner Start -->
    <div id="spinner" v-if="halamanloading"
        class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        &nbsp;<img src="../../../../src/assets/img/BerandaNgumahLogo.png" height="40">
        &nbsp;<span class="text-danger">..Loading...</span>
    </div>
    <!-- Spinner End -->
    <!-- Header Start -->
    <div class="container-fluid bg-breadcrumb">
        <div class="container text-center py-5" style="max-width: 900px;">
            <h3 class="text-white display-3 mb-4 wow fadeInDown pmisputih" data-wow-delay="0.1s">
                <span v-if="(bahasa === null) || (bahasa == 'ina')">Daftar Pemantauan Pemenuhan RTH
                    Kabupaten/Kota</span>
                <span v-else>Daftar Pemantauan Pemenuhan RTH Kabupaten/Kota</span>
            </h3>
            <ol class="breadcrumb justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s">
                <li class="breadcrumb-item">
                    <a href="/">
                        <span v-if="(bahasa === null) || (bahasa == 'ina')">Beranda</span>
                        <span v-else>Home</span>
                    </a>
                </li>
                <li class="breadcrumb-item active text-primary">
                    <span v-if="(bahasa === null) || (bahasa == 'ina')">Pemenuhan RTH Kabupaten/Kota</span>
                    <span v-else>Pemenuhan RTH Kabupaten/Kota</span>
                </li>
            </ol>
        </div>
    </div>
    <!-- Header End -->
    <!-- Contact Start -->
    <div class="container-fluid blog py-2">&nbsp;</div>
    <div class="container-fluid bg-breadcrumbberanda wow fadeInDown">
        <div class="card-body">
            <div class="tab-content" id="custom-tabs-four-tabContent">
                <div class="tab-pane fade show active" id="custom-tabs-four-home" role="tabpanel"
                    aria-labelledby="custom-tabs-four-home-tab">
                    <div class="container-fluid feature py-3">
                        <div class="section-title mb-0 wow fadeInUp" data-wow-delay="0.1s">
                            <div class="sub-style">
                                <h4 class="sub-title px-3 mb-0">
                                    RTH
                                </h4>
                            </div>
                        </div>
                    </div>
                    <div class="row px-2">
                        <div class="col-sm-12 wow fadeInUp" data-wow-delay="0.1s">
                            <div class="table-responsive table-wrapper">
                                <table class="table m-0" id="tabelinvestasi">
                                    <thead>
                                        <tr>
                                            <td>No</td>
                                            <td>Kabupaten/Kota</td>
                                            <td width="150px">Luas Perkotaan<br>(Ha)</td>
                                            <td width="150px">
                                                Luas Kebutuhan RTH Publik<br>(Ha)
                                            </td>
                                            <td width="150px">
                                                Luas RTH Publik<br>(Ha)
                                            </td>
                                            <td width="150px">Luas Kekurangan RTH Publik<br>(Ha)</td>
                                            <td width="150px">RTH Publik<br>(%)</td>
                                            <td width="150px">Kekurangan RTH Publik<br>(%)</td>
                                        </tr>
                                    </thead>
                                    <template v-for="(datalist, urutlist) in datamaster" :key="urutlist">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    {{ urutlist + 1 }}
                                                </td>
                                                <td>
                                                    {{ datalist.NAMA }}
                                                </td>
                                                <td class="text-center">
                                                    {{ G_numFormat(datalist.perkotaan) }}
                                                </td>
                                                <td class="text-center">
                                                    {{ G_numFormat(datalist.kebutuhanrth) }}
                                                </td>
                                                <td class="text-center">
                                                    {{ G_numFormat(datalist.luasrth) }}
                                                </td>
                                                <td class="text-center">
                                                    {{ G_numFormat(datalist.kekurangantrh) }}
                                                </td>
                                                <td class="text-center">
                                                    {{ G_numFormatKoma(datalist.persenrth) }}
                                                </td>
                                                <td class="text-center">
                                                    {{ G_numFormatKoma(datalist.kekurangan) }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
    <!-- Contact End -->
    <a href="#" class="btn btn-dangerbulet btn-lg-square back-to-top text-white"><i class="fa fa-arrow-up"></i></a>
    <Footer />
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import swal from 'sweetalert2';

import Top from "./Template/Top.vue";
import Footer from "./Template/Footer.vue";

export default {
    components: {
        Top, Footer, swal
    },
    data() {
        return {
            halamanloading: true,
            tahun: new Date().getFullYear(),
            bahasa: localStorage.getItem("bahasasistem"),
            sourcenya: '',
            datapesan: '',
            datamaster: [],
        };
    },
    methods: {
        async loadrth() {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer GPMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2",
                },
            });

            this.halamanloading = true;
            var random = Math.random();
            await mainAPI.get("parastapainnovationproses-GetRTH?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datamaster = Response.data.content.data;
                    }
                    else {
                        this.datapesan = [];
                        this.datamaster = [];
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datapesan = [];
                this.datamaster = [];

                this.halamanloading = false;
            });
        },
    },
    mounted() {
        this.loadrth();
    },
}
</script>

<style></style>